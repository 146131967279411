import { ApiCompanyDeleteAdministratorCompanyUserIdDeleteRequest, CompanyApi, WebApiCompanyDtoCompanyAddUserDto, WebApiCompanyDtoCompanyAddUserFromInviteDto, WebApiCompanyDtoCompanyDto, WebApiCompanyDtoCompanyUpdatePublicSiteDto, WebApiCompanyDtoCompanyUpdateUserDto } from "../Repository/eventbookyapi";
import { GetApiConfiguration } from "./ServiceConfiguration";
import { SubscriptionLevel } from "../Types/SubscriptionLevel";
import { AddOnProduct } from "../Types/AddOnProduct";

export abstract class CompanyService {
    public static GetCompanyAdministrators = async () => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyGetAdministratorsGet();
    };

    public static DeleteCompanyUser = async (companyUserId: number) => {
        const request: ApiCompanyDeleteAdministratorCompanyUserIdDeleteRequest = { companyUserId: companyUserId };
        return new CompanyApi(GetApiConfiguration()).apiCompanyDeleteAdministratorCompanyUserIdDelete(request);
    };


    public static AddCompanyUser = async (request: WebApiCompanyDtoCompanyAddUserDto) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyAddAdministratorPost({
            webApiCompanyDtoCompanyAddUserDto: request
        });
    };

    public static UpdateCompanyUser = async (request: WebApiCompanyDtoCompanyUpdateUserDto) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyUpdateAdministratorPost({
            webApiCompanyDtoCompanyUpdateUserDto: request
        });
    };

    public static ConnectUserToCompany = async (request: WebApiCompanyDtoCompanyAddUserFromInviteDto) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyConnectUserToCompanyPost({
            webApiCompanyDtoCompanyAddUserFromInviteDto: request
        });
    };

    public static GetCompanyUserInvite = async (inviteId: string) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyGetCompanyUserInviteByIdInviteIdGet({
            inviteId
        });
    };

    public static InitiatePayment = async (level: SubscriptionLevel, cancelurl: string) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyInitiatePaymentPost({
            level: level,
            cancelUrl: cancelurl
        });
    };

    public static InitiatePaymentAddOnProduct = async (addOnProduct: AddOnProduct, cancelurl: string) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyInitiatePaymentAddOnProductGet({
            addOnProduct: addOnProduct,
            cancelUrl: cancelurl
        });
    };

    public static ConfirmPayment = async (orderId: number) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyConfirmPaymentGet({ id: orderId });
    };

    public static UpdateCompanyPublicSite = async (request: WebApiCompanyDtoCompanyUpdatePublicSiteDto) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyUpdateCompanyPublicSitePost({
            webApiCompanyDtoCompanyUpdatePublicSiteDto: request
        });
    };

    public static GetCompanyPublicSite = async () => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyGetCompanyPublicSiteGet();
    };

    public static UpdateCompany = async (request: WebApiCompanyDtoCompanyDto) => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyPost({
            webApiCompanyDtoCompanyDto: request
        });
    };

    public static AddEmailDomain = async () => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyAddDomainPost();
    };

    public static DeleteEmailDomain = async () => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyDeleteDomainDelete();
    };

    public static GetEmailDomain = async () => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyGetDomainGet();
    };

    public static AuthenticateDomain = async () => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyAuthenticateDomainPut();
    };

    public static GetCompany = async () => {
        return new CompanyApi(GetApiConfiguration()).apiCompanyGet();
    };
};

