import { useEffect, useState } from 'react';
import { Col, Result, Row, Space } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { AuthProvider } from '../../Providers/authProvider';
import { TextService } from '../../Services/TextService';
import LeftMenuSettings from './LeftSettings';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { CompanyRole } from '../../Types/CompanyRole';
import { WebApiPaymentRepositoryStripeConfirmResponse } from '../../Repository/eventbookyapi';
import { CompanySubscriptionType } from '../../Types/CompanySubsriptionType';
import InnerHTML from 'dangerously-set-html-content'
import { CompanyService } from '../../Services/CompanyService';
import { formatToShortFormat } from '../../Helpers/DateHelpers';
import LayoutLeftMenu from '../../Components/Layout/LayoutLeftMenu';
import { AddOnProduct } from '../../Types/AddOnProduct';

const PaymentConfirmation = () => {
  const [user, setUser] = useState({} as AuthenticatedUser);
  const [confirmPayment, setConfirmPayment] = useState({} as WebApiPaymentRepositoryStripeConfirmResponse);
  let params = useParams();
  var ranOnce = false;

  useEffect(() => {

    if (params.id != null && !ranOnce) {
      CompanyService.ConfirmPayment(Number.parseInt(params.id)).then(paymentResult => {
        AuthProvider().GetLoggedInUser(true).then(user => {
          setUser(user!);
          setConfirmPayment(paymentResult);
        })
      });
    }

    ranOnce = true;

  }, []);

  return <LayoutLeftMenu menu={<LeftMenuSettings selected={"payments"} />}>
    {user.role === CompanyRole.GlobalAdmin ?
      <Col className='bookyBox' style={{ marginLeft: "20px", marginBottom: "20px" }} span={24}>

        {confirmPayment.hasSuccessfullyPayed ? <Result
          style={{ width: "500px" }}
          status="success"
          title={"Köpet genomfört"}
          extra={[
            <div>
              {confirmPayment.addOnProduct == AddOnProduct.SmsCredits500 ? <span>Tack! Du har nu köpt till 500 sms. En administratör på Onefront kommer inom kort lägga till 500 sms till ditt konto</span> :
                <span>Tack! Ditt konto är nu aktiverat och betalt. Det gäller tills {formatToShortFormat(confirmPayment.nextSubscriptionRenewalDate)}<br />En bekräftelse på ditt köp skickas inom kort till din mailadress.</span>}
            </div>
          ]}
        /> : <div></div>}
        <br></br>

      </Col>
      : ""}
  </LayoutLeftMenu>
};

export default PaymentConfirmation;