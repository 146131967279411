import { Col, Row, Spin, message, } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { AuthProvider } from '../../Providers/authProvider';
import { AuthenticatedUser } from '../../Types/AuthenticatedUser';
import { SubscriptionLevel } from '../../Types/SubscriptionLevel';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { EnterpriseFeatureList_SE, FreeFeatureList_SE, PremiumFeatureList_SE, BasicFeatureList_SE } from './features_se';
import { EnterpriseFeatureList_EN, FreeFeatureList_EN, PremiumFeatureList_EN, BasicFeatureList_EN } from './features_en';
import Page from '../../Components/Page/Page';
import { mapSubscriptionLevel } from '../../Mappers/mapSubscriptionLevel';
import { CompanyService } from '../../Services/CompanyService';
import FeatureBoxFree from './FeatureBoxFree';
import FeatureBoxBasic from './FeatureBoxBasic';
import FeatureBoxPremium from './FeatureBoxPremium';
import FeatureBoxEnterprise from './FeatureBoxEnterprise';

const Pricing = () => {
  const navigation = useNavigate();
  const [user, setUser] = useState(null as AuthenticatedUser | null);
  const GetText = TextService.GetText;

  useEffect(() => {
    AuthProvider().GetLoggedInUser().then(user => {
      setUser(user);
    });
  }, []);

  const redirectToCreateUser = (level: SubscriptionLevel) => {
    if (level === SubscriptionLevel.Free) {
      navigation("/" + getCurrentLanguage() + "/CreateUser");
    }
    else {
      navigation("/" + getCurrentLanguage() + "/CreateUser?subscription=" + mapSubscriptionLevel(level))
    }
  };

  const initiatePayment = async (level: SubscriptionLevel) => {
    if (level === SubscriptionLevel.Free) {
      message.warning("Du måste välja en kontonivå");
      return
    }
    CompanyService.InitiatePayment(level, window.location.href).then(result => {
      window.location.href = result.url!;
    });
  };

  const FreeFeatureList = () => {
    return getCurrentLanguage() == "se" ? FreeFeatureList_SE : FreeFeatureList_EN
  }

  const PremiumFeatureList = () => {
    return getCurrentLanguage() == "se" ? PremiumFeatureList_SE : PremiumFeatureList_EN
  }

  const BasicFeatureList = () => {
    return getCurrentLanguage() == "se" ? BasicFeatureList_SE : BasicFeatureList_EN
  }

  const EnterpriseFeatureList = () => {
    return getCurrentLanguage() == "se" ? EnterpriseFeatureList_SE : EnterpriseFeatureList_EN
  }

  return <Page pageTitle={GetText("PageTitle_Pricing")}>
    <Title style={{ textAlign: "center" }}>{GetText("Pricing_Title")}</Title>

    <Row justify="center" align="top" style={{ paddingTop: "40px" }}>

      <Row justify="center" align="top">
        <Col xs={24} md={7} xl={5} span={5}>
          <FeatureBoxFree
            user={user}
            description={GetText("Pricing_Free_Description")}
            featureList={FreeFeatureList()}
            redirectToCreateUser={redirectToCreateUser}
          />
        </Col>

        <Col xs={24} md={7} xl={5} span={5}>
          <FeatureBoxBasic
            title={GetText("Pricing_Basic")}
            user={user}
            subscriptionLevel={SubscriptionLevel.Basic}
            pricing={GetText("Pricing_BasicPayment")}
            description1={GetText("Pricing_BasicDescription")}
            mail={"3000-12000 " + GetText("Pricing_EmailYearly")}
            featureList={BasicFeatureList()}
            upgradeToText={GetText("Pricing_UpgradeToBasic")}
            redirectToCreateUser={redirectToCreateUser}
            initiatePayment={initiatePayment}
          />
        </Col>

        <Col xs={24} md={7} xl={5} span={5}>
          <FeatureBoxPremium
            title={GetText("Pricing_Premium")}
            user={user}
            subscriptionLevel={SubscriptionLevel.Premium}
            pricing={GetText("Pricing_PremiumPayment")}
            description={GetText("Pricing_PremiumDescription")}
            mail={"30 000 " + GetText("Pricing_EmailYearly")}
            featureList={PremiumFeatureList()}
            upgradeToText={GetText("Pricing_UpgradeToPremium")}
            redirectToCreateUser={redirectToCreateUser}
            initiatePayment={initiatePayment}
          />
        </Col>

        <Col xs={24} md={7} xl={5} span={5}>
          <FeatureBoxEnterprise
            title={GetText("Pricing_Enterprise")}
            user={user}
            subscriptionLevel={SubscriptionLevel.Enterprise}
            pricing={GetText("Pricing_EnterprisePayment")}
            description={GetText("Pricing_EnterpriseDescription")}
            mail={"120 000 " + GetText("Pricing_EmailYearly")}
            featureList={EnterpriseFeatureList()}
            upgradeToText={GetText("Pricing_UpgradeToEnterprise")}
            redirectToCreateUser={redirectToCreateUser}
            initiatePayment={initiatePayment}
          />
        </Col>
      </Row>
    </Row>
  </Page>
};

export default Pricing;