import { Select, SelectProps } from "antd";
import { MemberPaymentStatus } from "../../Types/MemberPaymentStatus";
import { TextService } from "../../Services/TextService";
import { getCurrentYearAsString, getUpcomingYear } from "../../Helpers/DateHelpers";

export const BookySelectPaymentStatus = (props: any) => {
    const GetText = TextService.GetText;

    const paymentStatus = () => {
        const options: SelectProps['options'] = [];

        options.push({
            label: GetText("BookyPaymentStatusSelect_Payed"),
            value: MemberPaymentStatus.Payed,
        });

        options.push({
            label: GetText("BookyPaymentStatusSelect_Unpayed"),
            value: MemberPaymentStatus.UnPayed,
        });

        options.push({
            label: GetText("BookyPaymentStatusSelect_Unpayed_LastYear"),
            value: MemberPaymentStatus.UnPayedLastYear,
        });

        return options;
    }

    const handlePaymentChange = async (value: MemberPaymentStatus) => {
        props.onSelectPaymentChanged(value);
    };

    return <Select style={props.style} className={props.className} placeholder={GetText("BookyPaymentStatusSelect_Select")} onChange={handlePaymentChange} allowClear options={paymentStatus()}></Select>;
}