import { FC } from 'react';
import { Button, Col, Collapse, Divider, Row, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import Page from '../../Components/Page/Page';
import BookyBox from '../../Components/Layout/BookyBox';
import { TextService } from '../../Services/TextService';
import { getCurrentLanguage } from '../../Helpers/RouteHelper';
import { useNavigate } from 'react-router-dom';

const PartnerFortnox = () => {
    const GetText = TextService.GetText;
    return <Page pageTitle={GetText("PageTitle_FortNox")}>
        <BookyBox>
            <Title>Integration till Fortnox</Title>

            <Paragraph>OneFront 360 kan nu erbjuda en tidsbesparande integration till Fortnox för företag och föreningar.<br /><br />
                Fakturor och betalningar som görs via Fortnox kan läsas in till OneFront 360 och automatiserar t.ex. medlemsavprickning av årsavgift. Efter den första uppsättningen sker sedan kopplingen per automatik och information från Fortnox läses in till OneFront.
                För att använda integrationen behöver du ha ett konto hos OneFront 360, <a href={"/" + getCurrentLanguage() + "/Pricing"}>läs mer och skapa konto.</a>
            </Paragraph>
            <br /><br />
            <h2>Om Fortnox</h2>
            <img
                alt="Fortnox logotype"
                style={{ width: "400px" }}
                src={require('../../Images/fortnox.png')}
            />
            <Paragraph>
                Fortnox är en företagsplattform med produkter inom bland annat redovisning, fakturering, finansiering och medarbetare.<br />
                Kunderna får också tillgång till andra företagstjänster och till hundratals externa system genom partnersamarbeten.<br />
                Fortnox grundades 2001 med huvudkontor i Växjö. Aktien är noterad på Nasdaq Stockholms huvudlista.<br />
                För ytterligare information se <a target="_blank" href="https://www.fortnox.se">www.fortnox.se</a>
            </Paragraph>
        </BookyBox>
    </Page>
};

export default PartnerFortnox;