import { TableColumnsType } from "antd";
import { TextService } from "../../Services/TextService";

export interface DataType {
    key: React.Key;
    memberLimit: string;
    emailLimit: number;
    price: string;
}

export const getPricingTableColumns = (textService: TextService): TableColumnsType<DataType> => {
    const GetText = TextService.GetText;
    return [
        {
            title: GetText("Common_Members"),
            dataIndex: 'memberLimit',

        },
        {
            title: GetText("Common_Email"),
            dataIndex: 'emailLimit',
        },
        {
            title: GetText("Common_Price"),
            dataIndex: 'price',
        },
    ];
}

export const basicDataOptions: DataType[] = [
    {
        key: '1',
        memberLimit: '0-250',
        emailLimit: 3000,
        price: '900',
    },
    {
        key: '2',
        memberLimit: '250-500',
        emailLimit: 6000,
        price: '1200',
    },
    {
        key: '3',
        memberLimit: '500-1000',
        emailLimit: 12000,
        price: '1500',
    },
];

export const premiumDataOptions: DataType[] = [
    {
        key: '1',
        memberLimit: '0-2500',
        emailLimit: 30000,
        price: '3000',
    },
];

export const freeDataOptions: DataType[] = [
    {
        key: '1',
        memberLimit: '0-25',
        emailLimit: 0,
        price: '0',
    },
];

export const enterpriseDataOptions: DataType[] = [
    {
        key: '1',
        memberLimit: '0-10000',
        emailLimit: 120000,
        price: '6000',
    },
];